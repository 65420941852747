<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Situação após o rompimento"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                A comunidade de Pedras hoje em dia se encontra num processo de
                êxodo, pois os seus moradores mais jovens se encontram sem boas
                perspectivas ao continuar vivendo ali, e partem para outras
                localidades em busca de melhores oportunidades de trabalho e de
                vida.
              </p>
              <p>
                Além disso, o meio ambiente como um todo foi afetado pela lama
                de rejeitos, impedindo o desenvolvimento de atividades agrícola,
                pecuária e de extrativismo. A renda dos moradores de Pedras foi
                fortemente afetada pela contaminação do solo e das águas, visto
                que a principal atividade econômica do distrito se baseava na
                agropecuária. O receio quanto à qualidade dos alimentos lá
                produzidos e da água disponível atinge não só a população local,
                como também os consumidores da produção rural que atualmente
                temem por sua saúde. Os gastos com alimentação e a
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_cultura_alimentar' }"
                  >insegurança alimentar</router-link
                >
                da região aumentaram consideravelmente após o rompimento da
                barragem de Fundão.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Outro ponto destacado pelos moradores do subdistrito é a perda
                de relações sociais, gerada pela destruição do campo de futebol
                e da dificuldade de acesso à comunidade. Desde novembro de 2015,
                as celebrações, festividades tradicionais, cavalgadas e partidas
                de futebol que ocorriam em Pedras deixaram de receber tantos
                visitantes da região, estando hoje reduzidas em quantidade de
                público e frequência.
              </p>
              <p>
                A vida na comunidade de Pedras foi profundamente alterada após o
                desastre–crime, havendo diversos relatos de moradores sobre a
                perda da tranquilidade, de salubridade e de interações sociais.
              </p>
              <p style="text-align: center">
                <strong
                  >Autora: Ludmilla Guimarães Novaes e Oliveira<sup
                    >1</sup
                  ></strong
                >
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela Universidade Federal de
              Ouro Preto. Assessora técnica na ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
